/** @jsx jsx */
import { jsx } from 'theme-ui';
import { Link } from 'gatsby';
import { Image } from 'rebass';
import logo from '../../assets/logo-vrelec.svg';

function Footer() {
  return (
    <footer
      sx={{
        fontSize: 3,
        color: 'background',
        bg: 'text',
        variant: 'styles.footer',
        mt: 5,
      }}
    >
      <div
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          alignItems: 'center',
          maxWidth: 1200,
          mx: 'auto',
          mt: 2,
          px: 2,
          py: 3,
        }}
      >
        <Link
          to="/"
          sx={{ variant: 'styles.footer.navlink', py: [3, 1], px: 3 }}
        >
          Domov
        </Link>
        {/* <a
          href="https://vrelec.si"
          sx={{ variant: 'styles.footer.navlink', py: [3, 1], px: 3 }}
        >
          Vrelec
        </a> */}
        <div sx={{ mx: 'auto' }} />
        <div sx={{ py: [3, 1], px: 3 }}>
          © {new Date().getFullYear()}
          <Link to="/">
            <Image
              // width={[1, 1, 1 / 2]}
              height={[30, 30, 35, 40]}
              pt={[2]}
              pl={[2, 2, 3]}
              src={logo}
              alt="Vrelec"
            />
          </Link>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
